/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import axios from 'axios';
import { Buffer } from 'buffer';
import md5 from 'md5';

const username = 'APIServerAdmin';
const password = 'admti21#@pp';

const usernamePasswordBuffer = Buffer.from(`${username}:${password}`);
const base64data = usernamePasswordBuffer.toString('base64');
const api = axios.create({
  baseURL: 'http://app.branyl.com.br:8082',
});
export default api;

api.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Basic ${base64data}`;
  return config;
});

export const createSession = async (email, password) => {
  return api.post(`validalogin?usuario=${email}&senha=${password}`);
};

export const apiNode = axios.create({
  baseURL: 'http://200.231.84.18:8085/',
});

apiNode.interceptors.request.use((config) => {
  config.headers.Authorization = md5('APIServerAdminadmti21#@pp');
  return config;
});

export const apiTeste = axios.create({
  baseURL: 'http://localhost:8085/',
});

const idRep = localStorage.getItem('idrep');

apiTeste.interceptors.request.use((config) => {
  config.headers.Authorization = md5('APIServerAdminadmti21#@pp');
  config.headers.idrepresentante = idRep;
  return config;
});
